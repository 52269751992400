<template>
  <div class="full-width mb-10">
    <div class="mb-4">
      <span
        v-if="computedLevels"
        class="mr-2"
      >{{computedLevels.length}}</span>
      <span>{{computedLabel}}</span>
      <v-btn
        v-if="!readonly"
        fab
        x-small
        color="primary"
        @click="addLevel"
        class="ml-4"
      >
        <v-icon>{{$icon('i.Plus')}}</v-icon>
      </v-btn>
    </div>
    <v-list
      expand
      flat
      class="background-translucid border-radius levels"
    >
      <draggable
        v-model="computedLevels"
        handle=".drag-handle"
        @end="dragEnd"
      >
        <v-list-group
          v-for="(level, index) in computedLevels"
          :key="index"
          v-model="openLevels[index]"
          class="background-translucid"
          no-action
          eager
        >
          <template v-slot:prependIcon>
            <v-icon class="drag-handle">{{$icon('i.Menu')}}</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center">
                <slot
                  name="level-header"
                  :index="index"
                  :is-open="openLevels[index]"
                />
                <v-spacer />
                <slot
                  name="level-header-infos"
                  :index="index"
                  :is-open="openLevels[index]"
                />
                <slot name="level-actions">
                  <!-- <v-text-field
                    ref="levels"
                  /> -->
                  <quick-actions
                    :actions="actions"
                    :attach="false"
                    :item="index"
                    v-if="!readonly"
                  />
                </slot>
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item class="background-plain">
            <v-list-item-content ref="levels">
              <slot
                name="level-content"
                :index="index"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </draggable>
    </v-list>
  </div>
</template>

<script>
export default {
  components: {
    QuickActions: () => import('@/components/quick-actions.vue'),
    Draggable: () => import('vuedraggable')
  },
  computed: {
    actions () {
      return [
        ...this.customActions,
        {
          text: this.$t('t.Add'),
          icon: this.$icon('i.Plus'),
          action: index => this.addLevel(),
          isVisible: () => true
        },
        {
          text: this.$t('t.Duplicate'),
          icon: this.$icon('i.Duplicate'),
          action: index => this.duplicateLevel(index),
          isVisible: () => true
        },
        {
          text: this.$t('t.Delete'),
          icon: this.$icon('i.Delete'),
          action: index => this.removeLevel(index),
          isVisible: () => true
        }
      ]
    },
    computedLabel () {
      return this.label ?? this.$t('t.Levels')
    },
    computedLevels: {
      get () { return this.dataLevels },
      set (v) {
        this.dataLevels = v
        this.dataDocumentEmit()
      }
    }
  },
  data () {
    return {
      dataLevels: [],
      openLevels: [],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const doc = this.dataLevels

      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    addLevel () {
      this.dataLevels.push(this.defaultLevel())

      this.dataDocumentEmit()
      this.openLevels = []
      this.dataLevels.forEach(l => {
        this.openLevels.push(false)
      })
      this.openLevels[this.openLevels.length - 1] = true

      // todo marco: scrollIntoView, cause toolbar to disapear...
      // this.$waitFor(() => this.$refs?.levels?.length).then(() => {
      //   this.$refs.levels[this.$refs.levels.length - 1].scrollIntoView({ behavior: 'smooth' })
      // })
    },
    dragEnd (e) {
      const openLevel = this.openLevels.splice(e.oldIndex, 1)[0]
      this.openLevels.splice(e.newIndex, 0, openLevel)
      this.dataDocumentEmit()
    },
    duplicateLevel (index) {
      const newLevel = this.lodash.cloneDeep(this.dataLevels[index])
      this.dataLevels.splice(index + 1, 0, newLevel)
      this.openLevels.splice(index + 1, 0, this.lodash.cloneDeep(this.openLevels[index]))
      this.dataDocumentEmit()
      this.$emit('duplicate-level', newLevel)
    },
    removeLevel (index) {
      this.dataLevels.splice(index, 1)
      this.openLevels.splice(index, 1)
      this.$emit('remove-level', index)
      this.dataDocumentEmit()
    }
  },
  props: {
    value: Array,
    customActions: {
      type: Array,
      default: () => []
    },
    document: Array,
    defaultLevel: Function,
    readonly: Boolean,
    label: String
  },
  watch: {
    document: {
      immediate: true,
      deep: true,
      handler (v) {
        if (v?.length) {
          this.dataLevels = this.lodash.cloneDeep(v)
        } else {
          this.addLevel()
        }

        this.dataDocumentEmit()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.full-width
  width 100%

>>> .v-list-group__header__prepend-icon
  align-self center
  margin-top 0
  margin-bottom 0
</style>
